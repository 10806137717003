import { triggerAnalyticsEvent } from './analytics'

const analyticsCookieKey = import.meta.env.VITE_ANALYTICS_COOKIE_KEY;

class CookieBar {
  constructor(cookiesHandler) {
    this.cookiesBar = document.getElementById("cookies-bar");
    this.cookiesHandler = cookiesHandler;
    this.cookiesBarVisible = "cookie-bar--visible";
  }

  init() {
    this.cookiesHandler.fillCookieGrantedInfo();

    if (!this.cookiesBar) return;

    if (this.cookiesHandler.isCookiesAllowed()) {
      this.enableTracks();
    } else {
      this.show();
    }
  }

  show() {
    this.cookiesBar.classList.remove("cookie--invisible");
    document.documentElement.classList.add(this.cookiesBarVisible);

    this.addButtonBehaviors();
  }

  addButtonBehaviors() {
    this.cookiesBar
			.querySelector(".accept")
			.addEventListener("click", () => this.allowCookies(true));
  }

  enableTracks() {
    // Google Tag Manager
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 100); // 100 years
    this.cookiesHandler.setCookie(analyticsCookieKey, "true", expirationDate);

    this.cookiesHandler.fillCookieGrantedInfo();
    document.documentElement.classList.remove(this.cookiesBarVisible);
  }

  allowCookies(allow) {
    if (allow) {
      this.cookiesHandler.allowCookies();
      this.enableTracks();
      triggerAnalyticsEvent('consent_granted');
    }

    this.cookiesBar.classList.add("cookie--invisible");
    document.documentElement.classList.remove(this.cookiesBarVisible);
  }
}

export default CookieBar;
